import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-areas-measurements-trx-mx",
  templateUrl: "./areas-measurements-trx-mx.component.html",
  styleUrls: ["./areas-measurements-trx-mx.component.scss"]
})
export class AreasMeasurementsTrxMxComponent implements OnInit {
  @Input() transaction: any;
  typeOpt = 1;
  typeOptCurrency = 1;
  platformId = environment.platformId;
  constructor() {}

  ngOnInit(): void {}
}
