import { Component, Input, OnInit } from "@angular/core";
import { I18nService } from "../../services";
import { TransactionsService } from "../../services/transactions.service";
import { environment } from "../../../../environments/environment";
import { CommonGenericService } from "../../services/common.service";
import { DatePipe } from "@angular/common";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

export interface ColumnDef {
  key: string;
  header: string;
  type: "date" | "currency" | "number" | "percentage" | "string" | "locale";
  render?: (txn: Transaction) => string;
  truncate?: boolean;
  hover?: boolean;
  maxWidth?: string;
}

export interface Transaction {
  id: number;
  type: "SALE" | "LEASE";
  date: Date;
  price: number;
  area: number;
  pricePerSquareMeter: number;
  capRate?: number;
  leaseTerm?: number;
  monthlyRent?: number;
  saleDate: string;
  leaseDate: string;
  soldArea: number;
  status: string;
}

export interface FormattedTransaction {
  [key: string]: string;
}

const monthNamesMap = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic"
};

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"]
})
export class TransactionsComponent implements OnInit {
  @Input() buildingId: number;
  @Input() transactionId: number;
  @Input() buildingTitle: string;
  @Input() txnType: "LEASE" | "SALE";
  browserLang;

  transactions: Transaction[] = [];
  activeTab: "LEASE" | "SALE";

  leaseFormattedTransactions: FormattedTransaction[] = [];
  saleFormattedTransactions: FormattedTransaction[] = [];
  platformId = environment.platformId;

  saleColumnDefs: ColumnDef[] = [
    {
      key: "saleDate",
      header: this.i18n.get("transaction.saleDate"),
      type: "date",
      render: (txn: Transaction) => {
        const txnId = txn.id;
        const txnDate =
          this.browserLang === "pt"
            ? this.datePipe.transform(txn.saleDate, "dd/MM/yyyy")
            : this.formatDate(txn.saleDate);
        return this.buildTxnDetailsURL(txnId, txnDate);
      }
    },
    {
      key: this.platformId == "mx" ? "salePriceUSD" : "salePrice",
      header:
        this.platformId == "mx"
          ? this.i18n.get("transaction.salePriceUsd")
          : this.i18n.get("transaction.salePriceR"),
      type: "currency"
    },
    {
      key: "soldArea",
      header: this.i18n.get("transaction.privateSoldArea"),
      type: "currency"
    },
    {
      key: this.platformId == "mx" ? "sellPricePerAreaUSD" : "txnAmountPerMFt",
      header:
        this.platformId == "mx"
          ? this.i18n.get("transaction.salePriceUsdM2")
          : this.i18n.get("transaction.pricePerSquareMeter"),
      type: "currency"
    },
    {
      key: "capRate",
      header: this.i18n.get("transaction.capRatePercent"),
      type: "percentage"
    }
  ];

  leaseColumnDefs: ColumnDef[] = [
    {
      key: "leaseDate",
      header: this.i18n.get("transaction.leaseDateT"),
      type: "date",
      render: (txn: Transaction) => {
        const txnId = txn.id;
        const txnDate =
          this.browserLang === "pt"
            ? this.datePipe.transform(txn.leaseDate, "dd/MM/yyyy")
            : this.datePipe.transform(txn.leaseDate, "MM/dd/yyyy");
        return this.buildTxnDetailsURL(txnId, txnDate);
      }
    },
    {
      key: "tenants",
      header: this.i18n.get("transaction.tenant"),
      type: "locale",
      truncate: true,
      hover: true,
      maxWidth: "140px"
    },
    {
      key: "floor",
      header: this.i18n.get("transaction.unit"),
      type: "string",
      truncate: true,
      maxWidth: "140px"
    },
    {
      key: "leasedArea",
      header: this.i18n.get("transaction.leasedArea"),
      type: "currency"
    },
    {
      key: "monthlyRentPerArea",
      header: this.i18n.get("detail.monthlyRentm2"),
      type: "currency"
    }
  ];

  constructor(
    private transactionService: TransactionsService,
    private i18n: I18nService,
    private commonService: CommonGenericService,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe
  ) {
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }

  ngOnInit(): void {
    this.setActiveTab(this.txnType);
    this.fetchTransactions();
  }

  getComponentTitle(): string {
    return `${this.i18n.get(
      "transaction.additionalTransactions"
    )} ${this.i18n.getTranslation(this.buildingTitle)}`;
  }

  fetchTransactions(): void {
    this.transactionService
      .getTransactions(this.buildingId)
      .then((data: Transaction[]) => {
        this.transactions = data;
        console.log("additional Transactions", this.transactions);

        var txnType = "LEASE";
        this.leaseFormattedTransactions = this.formatTransactions(
          this.splitTransactions(txnType),
          txnType
        );

        txnType = "SALE";
        this.saleFormattedTransactions = this.formatTransactions(
          this.splitTransactions(txnType),
          txnType
        );
      });
  }

  setActiveTab(tab: "LEASE" | "SALE"): void {
    this.activeTab = tab;
  }

  private isLeaseActive(): boolean {
    return this.activeTab === "LEASE";
  }

  disableToggle(tab: "LEASE" | "SALE"): boolean {
    if (tab === "LEASE") {
      return (
        !this.leaseFormattedTransactions ||
        this.leaseFormattedTransactions.length === 0
      );
    }
    return (
      !this.saleFormattedTransactions ||
      this.saleFormattedTransactions.length === 0
    );
  }

  toolTipText(tab: "LEASE" | "SALE"): string {
    if (this.disableToggle(tab)) {
      if (tab === "LEASE") {
        return this.i18n.get("transaction.noLeaseTransactions");
      }
      return this.i18n.get("transaction.noSaleTransactions");
    }
    return "";
  }

  getActiveTransactions(): FormattedTransaction[] {
    return this.isLeaseActive()
      ? this.leaseFormattedTransactions
      : this.saleFormattedTransactions;
  }

  splitTransactions(txnType: string): Transaction[] {
    const tmp = this.transactions.filter(
      t =>
        t.type === txnType &&
        (t.leaseDate != null || t.saleDate != null) &&
        t.status == "Active"
    );
    if (tmp && tmp.length > 0) {
      var sortedRecords;
      if (txnType === "LEASE") {
        sortedRecords = tmp.sort((a, b) =>
          b.leaseDate.localeCompare(a.leaseDate)
        );
      } else {
        sortedRecords = tmp.sort((a, b) =>
          b.saleDate.localeCompare(a.saleDate)
        );
      }
      return sortedRecords.slice(0, 5);
    }
    return [];
  }

  getActiveColumnDefs(txnType: string): ColumnDef[] {
    return txnType === "SALE" ? this.saleColumnDefs : this.leaseColumnDefs;
  }

  private formatTransactions(
    transactions: Transaction[],
    txnType: string
  ): FormattedTransaction[] {
    return transactions.map(transaction => {
      const formattedTransaction: FormattedTransaction = {};

      var columnDefs = this.getActiveColumnDefs(txnType);
      columnDefs.forEach(column => {
        if (column.render) {
          formattedTransaction[column.key] = column.render(transaction);
        } else {
          formattedTransaction[column.key] = this.formatCellValue(
            transaction[column.key as keyof Transaction],
            column.type
          );
        }
      });
      return formattedTransaction;
    });
  }

  private formatCellValue(value: any, type: string): string {
    if (value === undefined || value === null) {
      return "";
    }

    switch (type) {
      case "date":
        return value;
      case "locale":
        return this.i18n.getTranslation(value);
      case "currency":
        return this.commonService.formatNumberTo(value, 0);
      case "number":
        return value.toString();
      case "percentage":
        return this.commonService.formatNumberTo(value, 2);
      default:
        return value.toString();
    }
  }

  viewAll(): void {
    let url =
      `${environment.serverURL}/#!/building/${this.buildingId}/transactions` +
      (this.transactionId > 0 ? `?trxId=${this.transactionId}` : "");
    window.open(url, "blank");
  }

  legacyURL(): string {
    return `${environment.serverURL}/#!`;
  }

  buildTxnDetailsURL(txnId: number, txnDate: string): string {
    if (txnId) {
      return `<a href="/transaction/${txnId}/lang/${this.i18n.getCurrentLanguage()}" target="_blank">${txnDate}</a>`;
    }
    return txnDate;
  }

  formatDate(value) {
    if (value != "") {
      const date = new Date(value + "T00:00:00");
      const month = date.toLocaleString("en", { month: "short" });
      const year = date.getFullYear();

      return this.browserLang === "es"
        ? `${monthNamesMap[month]} ${year}`
        : `${month} ${year}`;
    }
    return value;
  }
}
