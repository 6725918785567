<div class="d-flex key-facts-columns border-table">
  <div class="col-md-12 p-0">
    <mat-button-toggle-group
      class="toggle-group w-100 toggle-options"
      appearance="legacy"
      name="fontStyle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOpt === 1 }"
        (click)="typeOpt = 1"
        class="toogle-btn border"
      >
        {{ "transaction.mxn" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          'active-toggle': typeOpt === 2
        }"
        (click)="typeOpt = 2"
        class="toogle-btn border"
      >
        {{ "transaction.usd" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOptCurrency === 1 }"
        (click)="typeOptCurrency = 1"
        class="toogle-btn border"
      >
        {{ "transaction.meters" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          'active-toggle': typeOptCurrency === 2
        }"
        (click)="typeOptCurrency = 2"
        class="toogle-btn border"
      >
        {{ "transaction.feet" | translate }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div class="d-content" *ngIf="typeOpt === 1">
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.soldArea && typeOptCurrency === 1"
    >
      {{ "transaction.privateSoldArea" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.soldArea && typeOptCurrency === 1"
    >
      {{ transaction?.soldArea | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.soldArea && typeOptCurrency === 2"
    >
      {{ "transaction.privateSoldAreaFt" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.soldArea && typeOptCurrency === 2"
    >
      {{ transaction?.soldArea * 10.764 | localNumber: "1.2-2" }}
    </div>
    <div class="column label-color flexB-60" *ngIf="transaction?.salePrice">
      {{ "transaction.salePriceMxn" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.salePrice">
      {{ transaction?.salePrice | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 1"
    >
      {{ "transaction.salePriceMxnM2" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 1"
    >
      {{ transaction?.sellPricePerArea | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 2"
    >
      {{ "transaction.salePriceMxnF2" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.sellPricePerArea && typeOptCurrency === 2"
    >
      {{ transaction?.sellPricePerArea * 10.764 | localNumber: "1.2-2" }}
    </div>
  </div>
  <div class="d-content" *ngIf="typeOpt === 2">
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.soldArea && typeOptCurrency === 1"
    >
      {{ "transaction.privateSoldArea" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.soldArea && typeOptCurrency === 1"
    >
      {{ transaction?.soldArea | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.soldArea && typeOptCurrency === 2"
    >
      {{ "transaction.privateSoldAreaFt" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.soldArea && typeOptCurrency === 2"
    >
      {{ transaction?.soldArea * 10.764 | localNumber: "1.2-2" }}
    </div>
    <div class="column label-color flexB-60" *ngIf="transaction?.salePriceUSD">
      {{ "transaction.salePriceUsd" | translate }}
    </div>
    <div class="column label-color2" *ngIf="transaction?.salePriceUSD">
      {{ transaction?.salePriceUSD | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 1"
    >
      {{ "transaction.salePriceUsdM2" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 1"
    >
      {{ transaction?.sellPricePerAreaUSD | localNumber: "1.2-2" }}
    </div>
    <div
      class="column label-color flexB-60"
      *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 2"
    >
      {{ "transaction.salePriceUsdF2" | translate }}
    </div>
    <div
      class="column label-color2"
      *ngIf="transaction?.sellPricePerAreaUSD && typeOptCurrency === 2"
    >
      {{ transaction?.sellPricePerAreaUSD * 10.764 | localNumber: "1.2-2" }}
    </div>
  </div>
  <div class="column label-color flexB-60" *ngIf="transaction?.noi">
    {{ "transaction.noiAtStabilization" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.noi">
    {{ transaction?.noi | localNumber: "1.2-2" }}
  </div>
  <div class="column label-color flexB-60" *ngIf="transaction?.capRate">
    {{ "transaction.capRateStbilized" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.capRate">
    {{ transaction?.capRate | localNumber: "1.2-2" }}%
  </div>
  <div class="column label-color flexB-60" *ngIf="transaction?.saleCommissions">
    {{ "transaction.saleCommissions" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.saleCommissions">
    {{ transaction?.saleCommissions | localNumber: "1.0-0" }}%
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bts || transaction?.bts == false"
  >
    {{ "transaction.bts" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bts || transaction?.bts == false"
  >
    {{
      transaction?.bts ? ("detail.yes" | translate) : ("detail.no" | translate)
    }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="
      transaction?.saleAndLeaseback || transaction?.saleAndLeaseback == false
    "
  >
    {{ "transaction.saleAndLeaseback" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      transaction?.saleAndLeaseback || transaction?.saleAndLeaseback == false
    "
  >
    {{
      transaction?.saleAndLeaseback
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
</div>

<div
  *ngIf="platformId == 'mx' && transaction?.type == 'SALE'"
  class="col-md-12 key-facts mt-2"
>
  <span>{{ "transaction.portafolioDetails" | translate }}</span>
</div>
<div
  *ngIf="platformId == 'mx' && transaction?.type == 'SALE'"
  class="d-flex key-facts-columns border-table"
>
  <div
    class="column label-color"
    *ngIf="transaction?.corporationPortfolio?.name"
  >
    {{ "transaction.portfolio" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.corporationPortfolio?.name"
  >
    {{ transaction?.corporationPortfolio?.name }}
  </div>
</div>
